html {
  font-family: $myfont;
}

body {
  background-color: white;
}

body,
button,
select,
table td {
  font-family: Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  vertical-align: top;
}

table td {
  font-family: Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  vertical-align: top;
}

$theme-colors: ( "primary": black, "danger": #ff4136, "hover": lightgray, );
// Here you can add other styles
.searchTitle {
  background-color: $oxford-blue !important;
  color: white !important;
  font-size: medium;
  font-family: inherit;
  line-height: 1.5;
}

.info {
  font-family: inherit;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  color: #334252;
  text-transform: uppercase;
}

.transcripts {
  font-family: inherit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  color: black;
  max-width: 160px;
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.sidebar {
  background-color: var(--mainColor);
  width: 65px !important;
  padding-top: 1rem;
}

.sidebar .navbar-nav .nav-link {
  padding: 1px !important;
  text-align: center;
}


html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 65px;
}

.sidebar .nav-item {
  padding-bottom: 1.2rem !important;
}

.sidebar .nav-link.active {
  background-color: var(--mainColor);
}

/* side bar hover color*/
.navbar-nav .nav-link .nav-line .nav-text:focus,
.sidebar .nav-text:focus,
.sidebar .nav-link:focus {
  border-radius: 4px;
  margin-left: 0;
  left: 0;
}


/* side bar hover color*/
.navbar-nav .nav-link .nav-line .nav-text:active,
.sidebar .nav-text:hover,
.sidebar .nav-link:hover {
  background-color: $sidebar-hover-color !important;
  border-radius: 4px;
  margin-left: 0;
}

.main .container-fluid {
  padding: 0 0px;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
  background-color: $oxford-blue !important;
}

.cancelButton {
  color: #0052cc !important;
  background-color: transparent;
  border: 1px solid #0052cc;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5; /* Line height */
  border-radius: 0.25rem; /* Rounded corners */
  cursor: pointer; /* Cursor changes to pointer when hovered over the button */
  transition: all 0.15s ease-in-out; /* Smooth transition for hover effects */
}

.cancelButton:hover, .cancelButton:focus {
  color: #fff !important; /* Text color changes on hover/focus */
  background-color: #0052cc; /* Background color changes on hover/focus */
  border-color: #0052cc; /* Border color remains the same */
}


.accordiomItem {
  position: relative;
  padding: 0;
  -webkit-box-shadow: 0px 1px 0px #e0e7eb;
  box-shadow: 0px 1px 0px #e0e7eb;
  background-color: #fff;
  border-top: 1px solid #e0e7eb;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
  -webkit-transition: height ease-in-out 50ms;
  -o-transition: height ease-in-out 50ms;
  transition: height ease-in-out 50ms;
}

.accordian-title {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  color: black;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.categoryButton {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 60px !important;
  margin-right: 5px;
  background-color: rgba(228, 229, 238, 0.4);
}

.show-best-button {
  background: none;
  border-radius: 25px !important;
}

@media all and (max-width: 30em) {
  .categoryButton {
    display: block;
    margin: 0.2em auto;
  }
}

.card-group>img {
  align-self: center;
}

.appProvider1 {
  display: flex;
  height: 40vh;
  overflow: auto;
  max-height: 50vh;
  top: 10%;
  backdrop-filter: blur(50px) !important;
}

.appProvider1-container>.popover {
  min-width: 55vw;
  min-height: 50vh;
  max-height: 30vh;
  top: 10%;
}

.appProvider {
  display: flex;
  height: 40vh;
  overflow: auto;
}

.filter-panel div.card div.card-body,
.filter-panel div.card div.card-body div.row {
  margin: 2px;
  padding: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.filter-panel .card,
.filter-panel .card-header,
.filter-panel .card-footer {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.filter-container>.popover {
  min-width: 30vw;
  position: absolute;
  will-change: transform;
  top: 60px !important;
  left: 20px !important;
  transform: translate3d(324px, 20px, 0px) !important;
}

.appProvider-container>.popover {
  min-width: 55vw;
  min-height: 60vh;
}

.filter-container .select__control {
  background: #ffffff;
  border: 1px solid #e4e5ee;
  box-sizing: border-box;
  height: 36px !important;
  background: #ffffff;
  border: 1px solid #e4e5ee;
  box-sizing: border-box;
  min-height: 36px !important;
}

.filter-panel .card-header {
  font-size: 12px;
  height: 12px;
}

.switch-secondary .switch-input:checked+.switch-slider {
  background-color: rgba(228, 229, 238, 0.5);
  border-color: #e4e5ee;
}

.switch-3d .switch-slider {
  background-color: rgba(228, 229, 238, 0.5);
  border-radius: 50em;
}

.switch-slider {
  position: relative;
  display: block;
  height: inherit;
  cursor: pointer;
  border-radius: 70px;
  background-color: rgba(228, 229, 238, 0.5);
}

.switch-label .switch-slider::after {
  position: absolute;
  top: 50%;
  right: 1px;
  z-index: unset;
  width: 50%;
  margin-top: -0.5em;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: #c8ced3;
  text-align: center;
  text-transform: uppercase;
  content: attr(data-unchecked);
  transition: inherit;
}

.switch-input:checked~.switch-slider::before {
  transform: translateX(14px);
  background-color: #0052cc;
  z-index: unset;
}

.switch-secondary .switch-input:checked+.switch-slider::before {
  border-color: #e4e5ee;
}

.switch-input:checked~.switch-slider::before {
  transform: translateX(14px);
  background-color: #0052cc;
}

.switch-3d .switch-slider::before {
  top: -1px;
  left: -1px;
  width: 26px;
  height: 26px;
  border: 0;
  border-radius: 50em;
}

.switch-slider::before {
  position: absolute;
  top: 2px;
  left: 2px;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  content: "";
  background-color: #a9acbd;
  border: 1px solid #e4e5ee;
  transition: 0.15s ease-out;
  border-radius: 0.125rem;
}

.font-12 {
  font-size: 12px;
  line-height: 1.43;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-13 {
  font-size: 13px !important;
  line-height: 1.4;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-14 {
  font-size: 14px !important;
  line-height: 1.43;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.user {
  margin-right: 50px;
  max-width: 40% !important;
}

.in-span {
  display: inline-flex;
}

.font-24 {
  font-size: 24px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  color: #2d323c;
}

.font-20 {
  font-size: 20px !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #2d323c;
}

.font-18 {
  font-size: 18px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  color: #2d323c;
}

.font-16 {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.bold {
  font-weight: bold;
}

.btn-primary {
  height: 36px;
  border: solid 0.5px #0052cc;
  background-color: #0052cc;
  font-size: 14px;
  color: #ffffff;
}

.btn-outline-primary {
  color: #0052cc;
  border: solid 0.5px #0052cc;
  font-size: 14px;
}

.btn {
  min-height: 2em !important;
  height: 3em !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: normal;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

input:placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #656886;
  opacity: 1;
  /* Firefox */
}

.form-control::placeholder {
  color: #656886;
  opacity: 1;
}

input.form-control {
  border-radius: 12px;
  //border: solid 0.5px $stroke-color;
}

.mr-top-bottom-10 {
  ///margin: 0.01rem 0.01rem;
}

.w-full {
  width: 100%;
}

.snippets-wrapper {
  padding: 5px 15px 15px 15px;
  height: calc(100vh - 185px);
  overflow-y: auto;
  background-color: white;
}

.snippet-wrapper {
  padding: 25px 15px;
  height: calc(100vh - 190px);
  overflow-y: auto;
  background-color: white;
}

.notes-wrapper {
  padding: 10px 10px;
  height: calc(100vh - 25rem);
  overflow-y: auto;
  background-color: white;
}

.editor-wrapper {
  max-height: calc(100vh - 400px);
  height: 100%;
  min-height: 10rem;
  overflow-y: auto;
  background-color: white;
}

.sw {
  &-list {
    display: flex;
  }
  &-col-list {
    padding-top: $select-item-gutter * 4;
    padding-bottom: $select-item-gutter * 4;
    border-bottom: 1px solid $stroke-color;
    &:hover {
      .sw-action {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &-vid {
    width: 21%;
    img {
      object-fit: cover;
      height: 100px;
      width: 100%;
    }
  }
  &-right-box {
    margin-left: 15px;
    width: 79%;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    small {
      padding: 6px 16px;
      margin-right: 15px;
      border-radius: 25px;
      display: inline-block;
    }
    >span {
      max-width: 70%;
    }
    span b {
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-info {
    display: inline-block;
    white-space: nowrap;
    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      margin-left: 3px;
      display: inline-flex !important;
    }
  }
  &-para {
    margin-top: $select-item-gutter * 2;
    margin-bottom: $select-item-gutter * 5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  &-action {
    opacity: 0;
    transition: 0.45s;
    pointer-events: none;
    div {
      margin-bottom: 0px;
      cursor: pointer;
      @media all and (max-width: 768px) {
        margin-bottom: 2px;
      }
    }
    .snippetmenu {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 5px;
      }
      &.show {
        background-color: #2d323c;
        border-radius: 3px;
      }
    }
    .sub-menu {
      padding: 15px 0px;
    }
    .dropdown-item {
      padding: 5px 20px;
      border: none;
      font-size: 12px;
      color: #ffffff !important;
      display: flex;
      align-items: center;
      i {
        display: none;
      }
    }
  }
}

.pd-15 {
  padding: 15px;
}

.playlist-modal-wrapper {
  max-width: 800px;
  .snippet-modal {
    height: auto;
    overflow: visible;
  }
  .pwm-option .dropdown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}

.newplaylist-modal-wrapper {
  //max-width: 600px;
  .snippet-modal {
    height: auto;
    overflow: visible;
  }
  .pwm-option .dropdown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}

.userprofile-modal-wrapper {
  max-width: 900px;
  .userprofile-modal {
    height: auto;
    overflow: visible;
  }
  .pwm-option .dropdown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}

.sharelink-modal-wrapper {
  max-width: 500px;
  .userprofile-modal {
    height: auto;
    overflow: visible;
  }
  .pwm-option .dropdown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}

.pwm-option {
  .dropdown {
    background-color: #fff;
    border: 1px solid #e1e2e9;
    border-radius: 8px;
    font-size: 14px;
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      font-weight: normal;
      background-color: inherit;
      box-shadow: unset;
      outline: none;
      min-width: 120px;
      border: none;
      padding: 0px 14px;
      height: auto !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:after {
        right: 15px;
        position: absolute;
        top: 13px;
        border-top: 0.4em solid;
        border-color: #70738b;
        border-right: 0.6em solid transparent;
        border-left: 0.6em solid transparent;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    .dropdown-item {
      color: #23282c !important;
      padding: 10px;
      line-height: 14px;
      border-bottom: none !important;
      font-size: 13px;
    }
  }
}

.role-option {
  max-width: 900px;
  .dropdown {
    background-color: #2d323c;
    border: 1px solid #e1e2e9;
    border-radius: 8px;
    font-size: 14px;
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      font-weight: normal;
      background-color: inherit;
      box-shadow: unset;
      outline: none;
      min-width: 120px;
      border: none;
      padding: 0px 14px;
      height: auto !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:after {
        right: 15px;
        position: absolute;
        top: 13px;
        border-top: 0.4em solid;
        border-color: #70738b;
        border-right: 0.6em solid transparent;
        border-left: 0.6em solid transparent;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    .dropdown-item {
      color: #23282c !important;
      padding: 10px;
      line-height: 14px;
      outline: none;
    }
  }
}

.pmw {
  &-wrapper {
    max-width: 390px;
    margin-bottom: 20px;
    input {
      background-color: #fff;
      border: 1px solid #e1e2e9;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 12px;
    }
  }
  &-title {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.delete-modal-wrapper {
  max-width: 700px;
  .modal-header {
    margin-bottom: 15px;
  }
  .modal-body {
    margin: 30px 0px;
    text-align: center;
  }
  .snippet-modal {
    height: auto;
    overflow: visible;
  }
  .dmw-text {
    font-size: 18px;
  }
  .pwm-option {
    margin-left: 20px;
    .dropdown .dropdown-menu {
      width: auto;
    }
    .dropdown-toggle {
      min-width: 165px;
    }
  }
}

.combined-share-modal-wrapper {
  max-width: 600px;
  padding: 0px;
  .snippet-modal {
    height: auto;
    overflow: visible;
  }
  .tab-content {
    border: none;
  }
  .share-success {
    margin-top: 10px;
  }
  .share-fill {
    margin-right: 14px;
  }
  .pwm-option {
    margin-left: 14px;
  }
  .modal-body {
    max-width: 600px;
    margin: 0px -25px;
    padding: 0px 25px !important;
    border-top: 1px solid #e1e2e9;
    border: none;
  }
  .modal-footer {
    padding-top: 25px !important;
    padding-right: 25px !important;
    max-width: 600px;
    margin: 0px -25px;
    margin-top: 10px;
    box-shadow: 0 -12px 20px 0 rgba(0, 0, 0, 0.1%);
  }
  .pwm-option {
    .dropdown-toggle {
      min-width: 165px;
    }
  }
}

.score-option {
  .dropdown {
    background-color: #fff;
    border: 1px solid #e1e2e9;
    border-radius: 8px;
    font-size: 14px;
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      font-weight: normal;
      background-color: inherit;
      box-shadow: unset;
      outline: none;
      min-width: 120px;
      border: none;
      padding: 0px 14px;
      height: auto !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:after {
        right: 15px;
        position: absolute;
        top: 13px;
        border-top: none;
        border-color: #70738b;
        border-right: 0.6em solid transparent;
        border-left: 0.6em solid transparent;
        border-bottom: 0.5em solid
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    .dropdown-item {
      color: #23282c !important;
      padding: 10px;
      line-height: 14px;
      border-bottom: none;
      font-size: 13px;
    }
  }
}

.share-modal-wrapper {
  max-width: 600px;
  .snippet-modal {
    height: auto;
    overflow: visible;
  }
  .share-success {
    margin-top: 10px;
  }
  .share-fill {
    margin-right: 14px;
  }
  .pwm-option {
    margin-left: 14px;
  }
  .modal-body {
    max-width: 600px;
    margin: 0px -25px;
    padding: 10px 25px !important;
    border-top: 1px solid #e1e2e9;
  }
  .modal-footer {
    padding-top: 25px !important;
    padding-right: 25px !important;
    max-width: 600px;
    margin: 0px -25px;
    margin-top: 30px;
    box-shadow: 0 -12px 20px 0 rgba(0, 0, 0, 0.1%);
  }
  .pwm-option {
    .dropdown-toggle {
      min-width: 165px;
    }
  }
}

.smw {
  &-input .search-icon {
    left: 16px;
  }
  &-input input {
    border: solid 1px #e4e5ee;
    background-color: #f8f9fc;
    font-size: 14px;
    height: 40px;
    padding-left: 45px;
    margin-top: 15px;
  }
  &-users {
    height: 200px;
    overflow-y: auto;
    padding: 10px 30px 10px 15px;
    border: solid 1px #e4e5ee;
    .form-check-input {
      position: relative;
    }
  }
  &-users-list {
    padding: 7px 0px;
    &-img {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: #0052cc;
      margin-right: 10px;
      padding-left: -5px;
    }
    &-text {
      padding-left: 3px;
      padding-top: 10px;
    }
    &-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 0px;
      }
      span {
        color: #6f7888;
        font-size: 12px;
      }
    }
  }
}


/*** setting-page ***/

.setting-container {
  background-color: var(--topBannerColor05);
  .page-title {
    padding: 1rem;
    margin: 0px;
    padding-bottom: 2px;
    color: #2D323C;
  }
  .tab-content {
    border: none;
    flex: 1;
    margin-top: 0px;
  }
}

.scblack {
  &-tabs {
    border: none;
    padding-bottom: 5px;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    font-weight: 500;
    font-size: 14px;
    fontfamily: inherit;
    @media all and (max-width: 1000px) {
      margin-left: 0px;
    }
    .nav-link {
      border: none;
      background: transparent;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2d323c;
      &.active {
        border-bottom: 3px solid var(--mainColor) !important;
        color: var(--mainColor) !important;
        background: transparent;
        font-weight: 500;
        padding-bottom: 5px;
      }
      &.disabled {
        color: lightgrey;
      }
    }
  }
}
.custom-nav-link {
  cursor: pointer;
  border: none;
}
.custom-nav-link.focus,
.custom-nav-link.hover {
  border: none white !important;
  background-color: rgba(88, 89, 255, 0.05) !important;
}

.custom-nav-link.active {
  color: #0c0e10;
  font-weight: bold;
  border: none;
  background-color: rgba(88, 89, 255, 0.1) !important;
  border-bottom: 3px solid rgba(88, 89, 255, 1) !important;
}

.sc {
  &-tab-container {
    display: flex;
    .sc-right-tab {
      flex: 1;
      padding: 0.5rem 2rem 1rem 0.5rem;
      width: 90%;
      height: calc(100vh - 120px);
      margin-bottom: 0px;
    }
  }
  &-tabs {
    border: none;
    margin-left: 3%;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 8px;
    @media all and (max-width: 1000px) {
      margin-left: 0px;
    }
    .nav-item {
      padding: 0px 2%;
      display: inline-block;
      //font-weight: 600;
      @media all and (max-width: 1000px) {
        padding: 0px 2px;
      }
      &:hover {
        a {
          background-color: var(--topBannerColor09) !important;
          cursor: pointer;
          color: var(--mainColor) !important;
        }
      }
    }
    .nav-link {
      border: none;
      background: transparent;
      text-align: center;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      //font-weight: 600;
      color: #2d323C !important;
      &.active {
        //border-bottom: 3px solid #2c0069;
        //color: #2c0069 !important;
        border-bottom: 3px solid var(--mainColor) !important;
        color: var(--mainColor) !important;
        background: transparent;
        font-weight: 600;
        padding-bottom: 5px;
      }
    }
  }
  &-content {
    display: flex;
    background-color: #fff;
    //font-weight: 600;
  }
  &-left-tab {
    width: 10rem;
    background-color: var(--topBannerColor05);
    ul,
    .actionTab.nav.flex-column.nav-tabs.nav-fill {
      //padding: 5px;
      border: none;
      li {
        cursor: pointer;
        //margin-bottom: 24px;
        text-align: left;
        color: #2d323C;
        border-radius: 4px;
        max-width: 10rem;
        font-size: 0.8rem;
        letter-spacing: -0.03em;
        transition: 0.45s;
        a {
          padding: 8px 12px !important;
          display: block;
          border: none !important;
          text-align: left;
        }
        a.active {
          border-bottom: 2px solid var(--mainColor) !important;
          color: var(--mainColor);
          font-weight: bold;
          //border-bottom: 2px solid #0052CC !important;
          //color: #0052CC;
        }
        &:hover {
          a {
            background-color: var(--topBannerColor02) !important;
            border: 1px solid white;
            cursor: pointer;
            color: var(--mainColor) !important;
          }
        }
      }
    }
  }
}

.sc-gen {
  &-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-table {
    margin-top: 10px;
    table {
      margin: 0px;
      thead tr th {
        border: none;
        padding: 16px 12px;
        font-weight: normal;
        width: 50%;
        color: #70738b;
        font-size: 14px;
        //font-weight: bold;
        text-transform: uppercase;
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        &:hover {
          .sc-user-team-action {
            opacity: 1;
          }
        }
      }
      tbody {
        border: 1px solid #aacbfc;
        display: block;
        max-height: calc(100vh - 390px);
        overflow-y: auto;
      }
      th,
      td {
        border-color: #aacbfc;
        padding: 16px 12px;
        width: 50%;
        font-size: 14px;
        color: #090b0e;
        //font-weight: bold;
        .custom-checkbox {
          padding-left: 24px;
        }
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
    }
  }
  &-btn {
    margin-top: 20px;
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

.td-flex {
  display: flex;
  align-items: center;
  .down-arrow-1 {
    margin-left: 10px;
  }
  .switch {
    margin-left: 7px;
    width: 32px;
    height: 22px;
    .switch-slider {
      height: 16px;
      &:before {
        width: 16px;
        height: 16px;
      }
    }
  }
  .mail {
    line-height: 1.5;
    color: #5a687a;
    margin-top: 1px;
  }
}

.sc-user {
  &-section {
    margin-top: 2px;
  }
  &-tab {
    list-style: none;
    display: flex;
    flex: 1;
    padding: 0px;
    li {
      cursor: pointer;
      margin: 0 0.5rem;
      text-align: center;
      padding: 1rem;
      width: 8rem;
      border: 1px solid #e4e5ee;
      border-radius: 8px;
      &.active {
        border: none;
        background-color: rgba(0, 82, 204, 0.1);
      }
      div:first-child {
        margin-bottom: 4px;
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    .btn {
      padding: 0px 10px;
      background-color: #0052cc;
    }
  }
  &-table {
    table {
      margin: 0px;
      thead,
      tbody tr {
        display: table;
        width: 100%;
      }
      tbody {
        border: 1px solid #aacbfc;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
      }
      tbody tr {
        table-layout: auto;
        white-space: nowrap;
      }
      thead tr th {
        border: none;
        padding: 16px 12px;
        width: 10% !important;
        box-shadow: none;
        color: #70738b;
        font-size: 14px;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
        td {
          &:nth-child(4),
          &:nth-child(5) {
            width: 120px;
          }
        }
      }
      tr {
        border-bottom: 1px solid #aacbfc !important;
        box-shadow: none !important;
      }
      tbody {
        border: 1px solid #aacbfc;
        tr {
          border-bottom: 1px solid #aacbfc;
          padding: 16px 12px;
          width: 10%;
          font-size: 14px;
          color: #090b0e;
          box-shadow: none;
          font-weight: normal;
          vertical-align: middle;
          .custom-checkbox {
            padding-left: 24px;
          }
          .custom-control-label {
            padding-left: 15px;
            line-height: 14px;
          }
          &:nth-child(3) {
            width: 5%;
          }
          &:nth-child(5),
          &:nth-child(6) {
            width: 8%;
          }
          &:nth-child(7) {
            width: 9%;
          }
        }
      }
      tr:hover {
        .sc-user-action {
          opacity: 1;
        }
      }
    }
  }
  &-status {
    padding: 3px 15px;
    border-radius: 25px;
    &.active {
      background-color: var(--topBannerColor2);
      color: #1ba171;
    }
    &.inactive {
      background-color: var(--topBannerColor1);
      color: #ff9900;
    }
  }
  &-action {
    opacity: 0;
    justify-content: flex-end;
    button,
    button:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: inline;
      width: 24px;
      background-color: gray;
      height: 24px;
      margin: 0px 4px;
      cursor: pointer;
      transition: 0.45s;
      img {
        max-width: 100%;
      }
    }
  }
}

.sc-user {
  &-team {
    table {
      margin: 0px;
      thead tr th {
        border: none;
        padding: 16px 12px;
        font-weight: normal;
        color: #70738b;
        font-size: 14px;
        //font-weight: bold;
        text-transform: uppercase;
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      tbody {
        border: 1px solid #aacbfc;
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
      }
      tbody {
        border: 1px solid #aacbfc;
        display: block;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
      }
      tbody tr {
        table-layout: auto;
        white-space: nowrap;
      }
      tr {
        border-bottom: 1px solid #aacbfc !important;
        box-shadow: none !important;
      }
      th,
      td {
        border-color: #aacbfc;
        padding: 16px 12px;
        font-size: 14px;
        color: #090b0e;
        font-weight: normal;
        .custom-checkbox {
          padding-left: 24px;
        }
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      tr:hover {
        .sc-user-team-action {
          opacity: 1;
        }
      }
    }
  }
  &-team-action {
    opacity: 0;
    button,
    button:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: inline;
      width: 24px;
      //background: url('../assets/img/blueEdit.svg') no-repeat;
      height: 24px;
      margin: 0px 4px;
      cursor: pointer;
      transition: 0.45s;
      img {
        max-width: 100%;
      }
    }
  }
}

.sc-user {
  &-newuser {
    table {
      margin: 0px;
      thead tr th {
        border: none;
        padding: 16px 12px;
        font-weight: normal;
        color: #70738b;
        font-size: 14px;
        //font-weight: bold;
        text-transform: uppercase;
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      tbody {
        border: 1px solid #aacbfc;
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
      }
      tbody {
        border: 1px solid #aacbfc;
        display: block;
        max-height: calc(100vh - 390px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      tbody tr {
        table-layout: auto;
        white-space: nowrap;
      }
      tr {
        border-bottom: 1px solid #aacbfc !important;
        box-shadow: none !important;
      }
      th,
      td {
        border-color: #aacbfc;
        //padding: 16px 12px;
        font-size: 14px;
        color: #090b0e;
        //font-weight: bold;
        .custom-checkbox {
          padding-left: 24px;
        }
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      tr:hover {
        .sc-user-newuser-action {
          opacity: 1;
        }
      }
    }
  }
  &-newuser-action {
    opacity: 0;
    button,
    button:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: inline;
      width: 24px;
      //background: url('../assets/img/blueEdit.svg') no-repeat;
      height: 24px;
      margin: 0px 4px;
      cursor: pointer;
      transition: 0.45s;
      img {
        max-width: 100%;
      }
    }
  }
}

.sc-user {
  &-role {
    &-title {
      margin-top: 10px;
    }
    table {
      margin: 0px;
      thead tr th {
        border: none;
        padding: 16px 12px;
        font-weight: normal;
        color: #70738b;
        font-size: 14px;
        //font-weight: bold;
        text-transform: uppercase;
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
      }
      tbody {
        border: 1px solid #aacbfc;
      }
      tr {
        border-bottom: 1px solid #aacbfc !important;
        box-shadow: none !important;
      }
      th,
      td {
        border-color: #aacbfc;
        padding: 16px 12px;
        font-size: 14px;
        color: #0052cc;
        //font-weight: bold;
        .custom-checkbox {
          padding-left: 24px;
        }
        .custom-control-label {
          padding-left: 15px;
          line-height: 14px;
        }
        &:first-child {
          //color: #2d323c !important;
        }
      }
      tr:hover {
        .sc-user-team-action {
          opacity: 1;
        }
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
      }
      tbody {
        border: 1px solid #aacbfc;
        display: block;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      tbody tr {
        table-layout: auto;
        white-space: nowrap;
      }
    }
  }
  &-team-action {
    opacity: 0;
    button,
    button:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: inline;
      width: 24px;
      //background: url('../assets/img/blueEdit.svg') no-repeat;
      height: 24px;
      margin: 0px 4px;
      cursor: pointer;
      transition: 0.45s;
      img {
        max-width: 100%;
      }
    }
  }
}

.payment-tab {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-left: -20px;
  max-height: calc(100vh - 150px);
  overflow-y: hidden;
  @media all and (max-height: 900px) {
    max-height: calc(100vh - 20px);
  }
}

.integration-tab {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-left: -20px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.integration-tab-onboarding {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-left: -20px;
  max-height: 60vh;
  padding-bottom: 30px;
}

.it {
  &-card {
    padding: 15px;
    border-radius: 16px;
    margin-bottom: 19px;
    margin-left: 13px;
    margin-right: 13px;
    width: calc((100% / 4) - 26px);
    box-shadow: 0 4px 20px 0 rgba(0, 82, 204, 0.1);
    transition: 0.45s;
    &:hover {
      box-shadow: 0 4px 25px 0 rgba(0, 82, 204, 0.1);
    }
    @media all and (max-width: 1280px) {
      width: calc((100% / 3) - 26px);
    }
    @media all and (max-width: 1000px) {
      width: calc((100% / 2) - 26px);
    }
    @media all and (max-width: 768px) {
      width: calc((100% / 1) - 26px);
    }
  }
  &-card2 {
    padding: 15px;
    border-radius: 16px;
    margin-bottom: 19px;
    margin-left: 13px;
    margin-right: 13px;
    width: calc((100% / 2) - 26px);
    box-shadow: 0 4px 20px 0 rgba(0, 82, 204, 0.1);
    transition: 0.45s;
    &:hover {
      box-shadow: 0 4px 25px 0 rgba(0, 82, 204, 0.1);
    }
    @media all and (max-width: 1280px) {
      width: calc((100% / 2) - 26px);
    }
    @media all and (max-width: 1000px) {
      width: calc((100% / 1) - 26px);
    }
    @media all and (max-width: 768px) {
      width: calc((100% / 1) - 26px);
    }
  }
  &-img {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 16px;
    img {
      max-width: 54px;
      max-height: 100%;
    }
  }
  &-title {
    font-weight: 600;
    div {
      font-size: 18px;
      color: #2d323c;
    }
    span {
      color: #70738b;
    }
  }
  &-info {
    margin-top: 13px;
    margin-bottom: 22px;
    min-height: 6rem;
    max-height: 6rem;
    @media all and (max-width: 1280px) {
      max-height: 5rem;
      min-height: auto;
      overflow: hidden;
    }
  }
  &-ctrls {
    margin-top: 15px;
    .add-btn {
      padding: 0 5px;
    }
    .currentPlan {
      padding: 0 5px;
      background-color: #eff0f4;
      cursor: default !important;
      color: #4A3AFF;
      border: 0 solid #4A3AFF;
      font-size: 1rem;
      font-weight: bold;
    }
    .choice {
      padding: 0 5px;
      background-color: #4A3AFF;
      cursor: none;
      color: #ffffff;
      border: 0 solid #4A3AFF;
      font-size: 1rem;
    }
    .add-inti {
      background-color: rgba(0, 82, 204, 0.1);
      padding: 0px 10%;
      height: 2.5em !important;
      color: #0052cc;
      font-size: 14px;
      &:nth-child(1) {

      }
      &.icon {
        padding: 0;
        width: 50px;
      }
      &:nth-child(2) {
        //background: url('../assets/img/refreshBlue.svg');
        margin: 0px 18px;
      }
    }
  }
}

.scdelete-modal-wrapper {
  max-width: 600px;
  margin-top: 40px;
  .modal-content {
    padding: 5px 15px;
  }
  .modal-header,
  .modal-footer {
    border: none;
    .close {
      display: none;
    }
  }
  .sc-tabs {
    margin-left: 0px;
    color: #ffffff;
    .nav-link .active {
      color: white !important;
    }

    .nav-item {
      padding: 0px;
      min-width: 100px;
      margin-right: 30px;
      a {
        padding: 10px 0px;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
  .tab-content {
    border: none;
  }
  .tab-pane {
    padding: 0px !important;
  }
  .aum-list {
    max-width: 440px;
    margin-bottom: 20px;
    flex: 1;
    .aum-title {
      font-weight: 500;
      font-size: 14px;
    }
    .snippet-name-error {
      color: red;
    }
    .pwm-option {
      margin-top: 4px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .aum-add-more {
    margin-top: 24px;
    margin-left: 15px;
  }
  button[type="button"] {
    min-width: 73px;
    color: #2d323c;
  }
  .aum {
    &-deact-link {
      position: absolute;
      right: 30px;
      cursor: pointer;
      top: 30px;
    }
    &-name-line {
      margin-top: -15px;
      margin-bottom: 5px;
    }
    &-email-line {
      margin-bottom: 15px;
    }
  }
}

.adduser-modal-wrapper {
  //max-width: 600px;
  margin-top: 40px;
  .modal-content {
    padding: 5px 15px;
  }
  .modal-header,
  .modal-footer {
    border: none;
    .close {
      display: none;
    }
  }
  .sc-tabs {
    margin-left: 0px;
    align-items: start;
    align-content: start;
    color: #ffffff;
    .nav-link .active {
      color: white !important;
    }

    .nav-item {
      padding: 0px;
      min-width: 100px;
      margin-right: 30px;
      a {
        padding: 10px 0px;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
  .tab-content {
    border: none;
  }
  .tab-pane {
    padding: 0px !important;
  }
  .aum-list {
    max-width: 440px;
    margin-bottom: 20px;
    flex: 1;
    .aum-title {
      font-weight: 500;
      font-size: 14px;
    }
    .snippet-name-error {
      color: red;
    }
    .pwm-option {
      margin-top: 4px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .aum-add-more {
    margin-top: 24px;
    margin-left: 15px;
  }
  button[type="button"] {
    min-width: 73px;
    color: #2d323c;
  }
  .aum {
    &-deact-link {
      position: absolute;
      right: 30px;
      cursor: pointer;
      top: 30px;
    }
    &-name-line {
      margin-top: -15px;
      margin-bottom: 5px;
    }
    &-email-line {
      margin-bottom: 15px;
    }
  }
}

.editrole-modal-wrapper {
  max-width: 800px;
  overflow: auto;
  padding-bottom: 0px;
  padding-left: 8px;
  //color: #2d323c;
  color: #202124;
  .modal-content {
    padding: 10px 5px;
  }
  .title {
    font-weight: normal;
  }
  .modal-header,
  .modal-footer {
    border: none;
    .close {
      display: none;
    }
  }
  .tab-content {
    border: none;
  }
  .tab-pane {
    padding: 0px !important;
    padding-top: 0px;
  }
  .aum-list {
    max-width: 800px;
    margin-bottom: 10px;
    flex: 1;
    .pwm-option {
      margin-top: 4px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .aum-add-more {
    margin-top: 10px;
    margin-left: 15px;
  }
  button[type="button"] {
    min-width: 73px;
    color: #2d323c;
  }
}

.editrole {
  &_box {
    width: 433px;
    height: 44px;
    margin: 16px 6px 4px 0;
    padding: 4px 4px 4px 12px;
    border-radius: 8px;
    border: solid 0.5px #ccd5e1;
    background-color: #ffffff;
    text-align: left;
    position: relative;
    transition: 0.45s;
    .down-arrow-1 {
      position: absolute;
      right: 10px;
      top: 12px;
      transform: rotate(-90deg);
    }
    &.active {
      .down-arrow-1 {
        transform: rotate(0deg);
      }
    }
  }
}

.Rectangle-209 {
  width: 30%;
  margin: 10px 20px;
  padding: 10px 15px;
  border-radius: 20px;
  boxshadow: 60px 60px 60px 60px rgba(0, 84, 204, 1);
  background-color: #fff;
  align-content: center;
  text-align: center;
  overflow-y: hidden;
  display: inline-block;
  @media all and (max-width: 1280px) {
    width: 29%;
    margin: auto;
    margin: 10px;
    padding: 10px;
  }
  @media all and (max-width: 1000px) {
    width: 28%;
    margin: auto;
    margin: 10px;
    padding: 10px;
  }
  @media all and (max-width: 768px) {
    width: 26%;
    margin: 5px;
    padding: 5px;
  }
  @media all and (max-height: 900px) {
    max-height: calc(100vh - 10px);
  }
}

.plans_box_text {
  padding: 10px 0px 10px 10px;
  height: 36px;
  margin: 10px 10px 10px 10px;
  font: inherit;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: left;
  color: #2d323c;
}

.plans_box {
  margin: 4px;
  padding: 8px 20px;
  border-radius: 16px;
  background-color: var(--mainColor);
  color: #ffffff;
  text-align: center;
}

.Rectangle-206 {
  width: 576px;
  height: 120px;
  margin: 20px 111px 20px 0;
  padding: 0 6px 0 40px;
  border: solid 1px #e4e5ee;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 20px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: rgba(0, 82, 204, 0.05);
}

.column-title {
  textalign: left;
  width: 86px;
  height: 21px;
  margin: 3px 12px 0px 0px;
  fontfamily: inherit;
  fontsize: 12px;
  fontstretch: normal;
  fontstyle: normal;
  lineheight: 1.5;
  letterspacing: normal;
  color: #2d323c;
}

.aum-list-transcription-accuracy {
  width: 384px;
  height: 287px;
  margin: 25px 25px 25px 45px;
  padding: 25px;
  borderradius: 51px;
  backgroundcolor: #fefefe;
  boxshadow: 10px 10px 80px #f2f1fb;
  .aum-list-transcription-accuracy-innerbox {
    width: 286px;
    height: 266px;
    margin: 0 0 0 0;
    padding: 22px 15px 22px 15px;
    borderradius: 10px;
    backgroundcolor: #ffffff;
  }
}

.meeting-modal-wrapper {
  max-width: 600px;
  overflow: auto;
  padding: 20px;
  color: #2d323c;
  .modal-content {
    padding: 15px 0px;
  }
  .title {
    font-weight: normal;
    font-size: 20px;
  }
  .modal-header,
  .modal-footer {
    border: none;
    //.close {
    //  display: none;
    //}
  }
  .tab-content {
    border: none;
  }
  .tab-pane {
    padding: 0px !important;
    padding-top: 0px;
  }
  .aum-list {
    max-width: 500px;
    margin-bottom: 10px;
    flex: 1;
    .pwm-option {
      margin-top: 4px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .aum-list-transcription-accuracy {
      width: 384px;
      height: 287px;
      margin: 19px 32px 27px 34px;
      padding: 15px 49px 16px 42px;
      borderradius: 51px;
      backgroundcolor: #fefefe;
      boxshadow: 10px 10px 80px #f2f1fb;
      .aum-list-transcription-accuracy-innerbox {
        width: 286px;
        height: 266px;
        margin: 0 0 0 0;
        padding: 20px 17px 24px 10px;
        borderradius: 10px;
        backgroundcolor: #ffffff;
      }
    }
  }
  .aum-add-more {
    margin-top: 10px;
    margin-left: 15px;
  }
  button[type="button"] {
    min-width: 73px;
    color: #0052cc;
  }
}

.aum-list {
  max-width: 500px;
  margin-bottom: 10px;
  font-size: 14px;
  //padding-left: 20px;
  //padding-right: 30px;
  padding: 0px;
}

.saveAlertModal {
  max-width: 500px;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 30px;
}

.full-modal {
  width: 100vw;
  max-width: calc(100vw - 100px);
  max-height: calc(100vh - 100px);
  height: 100vh !important;
}

///RichTextEditor Styles
.element-link {
  display: inline;
  position: relative;
}

.element-link .popup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  z-index: 1;
}

.element-link .popup a {
  display: flex;
  align-items: center;
  //gap: 5px;
  padding-right: 10px;
  border-right: 1px solid lightgrey;

}

.element-link .popup button {
  border: none;
  background: transparent;
}

.element-link .popup button:hover {
  color: rebeccapurple;
  cursor: pointer;
}