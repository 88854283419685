$md-chip-height: 32px;
$md-chip-color: #ffffff;

.md-chip {
  display: inline-block;
  background: $md-chip-color;
  padding: 0 12px;
  border-radius: 38px;
  border: solid 0.5px #0052cc;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  &.md-chip-hover:hover {
    background: #0052cc;
  }
}

.md-chip-clickable {
  cursor: pointer;
}

.md-chip,
.md-chip-icon {
  height: $md-chip-height;
  line-height: $md-chip-height;
}

.md-chip-icon {
  display: block;
  float: left;
  background: #009587;
  width: $md-chip-height;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 0 8px 0 -12px;
}

.md-chip-remove {
  display: inline-block;
  border: 0;
  height: 20px;
  width: 20px;
  max-width: 20px !important;
  min-width: 20px !important;
  border-radius: 50%;
  padding: 0;
  margin: 5px -4px 0 4px;
  cursor: pointer;
  font: inherit;
  line-height: 20px;
  &:after {
    color: #0052cc;
    content: "x";
  }
  &:hover {
    color: white;
    background: #536b8f;
  }
  &:active {
    background: white;
  }
}

.md-chips {
  padding: 1px 0;
  .md-chip {
    margin: 0 5px 3px 0;
  }
}

.md-chip-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
