$app-radio-checked-color: #2667c9;
$app-radio-border-color: #ccd5e1;
$app-radio-size: 24px;
$app-radio-checked-size: 10px;
$app-radio-ripple-size: 15px;
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $app-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $app-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.app-radio {
  margin: 16px 0;

  &.app-radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: $app-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    min-height: $app-radio-size;
    position: relative;
    padding: 0 ($app-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $app-radio-size;
      height: $app-radio-size;
      border: 0.5px solid $app-radio-border-color;
    }
    &:after {
      top: $app-radio-size / 2 - $app-radio-checked-size / 2;
      left: $app-radio-size / 2 - $app-radio-checked-size / 2;
      width: $app-radio-checked-size;
      height: $app-radio-checked-size;
      transform: scale(0);
      background: $app-radio-checked-color;
    }
  }
}

// *************************************

// *************************************
*,
*:before,
*:after {
  box-sizing: border-box;
}
