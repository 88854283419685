.userprofile-sec {
  display: block;
  padding: 10px 0 0 0;
  //background-color: rgba(25, 99, 209, 0.05);
  background-color: var(--topBannerColor05);
  .us-profile {
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 20px 15px 15px;
    width: 380px;
    margin-right: 20px;
    .img {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      margin: 0 auto 30px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
      }
      .circle {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 50%;
        font-size: 40px;
        text-align: center;
      }
      span {
        position: absolute;
        bottom: 0px;
        right: 15px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: block;
        background-color: rgba(25, 99, 209, 1);
      }
    }
    h4 {
      color: rgba(45, 50, 60, 1);
      font-family: inherit;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      margin: 0 0 10px;
    }
    p {
      color: rgba(45, 50, 60, 0.6000000238418579);
      font-family: inherit;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      margin: 0 0 15px;
    }
    .user-text {
      font-weight: 500;
      padding : 4px;
      text-align: center;

    }
    .total-list {
      color: rgba(0, 0, 0, 1);
      font-family: inherit;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      margin: 0 0 10px;
      white-space: nowrap;
      span {
        color: rgba(0, 82, 204, 1);
        font-family: inherit;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
      }
    }
    .upgrade-bx {
      margin: 40px 0 0;
      text-align: center;
      display: block;
      border-radius: 8px;
      min-height: 195px;
      padding: 5px;
      background-color: rgba(25, 99, 209, 0.1);
      h5 {
        color: rgba(45, 50, 60, 1);
        font-size: 14px;
        margin: 0 auto;
        max-width: 50%;
        text-align: center;
      }
      p {
        text-align: center;
        color: rgba(45, 50, 60, 1);
        font-family: inherit;
        font-weight: 500;
        font-size: 14px;
        margin: 25px 0 25px;
      }
      .btn-upgrade {
        width: 174px;
        height: 36px;
        background: rgba(0, 82, 204, 1);
        border-radius: 4px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: inherit;
        font-weight: 500;
        font-size: 14px;
        margin: 15px auto;
      }
    }
  }
  .us-right {
    width: calc(100% - 20px);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 25px;
    .form-control {
      min-height: 36px;
      background: rgba(255, 255, 255, 1);
      border: 0.5px solid rgba(228, 229, 238, 1);
      border-radius: 8px;
    }
    .nav-tabs {
      border-bottom: 0.5px solid #e4e5ee;
      .nav-link {
        border-width: 1.5px;
        &.active,
        &:hover,
        &:focus {
          border-color: transparent;
          border-bottom-color: #004cff;
        }
      }
    }
    .tab-content {
      margin: 0;
      border: 0;
      .btn-submit {
        width: 174px;
        height: 36px;
        background: #0052cc;
        border-radius: 4px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: inherit;
        font-weight: 500;
        font-size: 14px;
        margin: 30px  auto;
        &:hover {
          background-color: #136cf1;
        }
      }
      .setting-bx {
        h5 {
          color: rgba(0, 0, 0, 1);
          margin: 20px 0 15px;
          font-family: inherit;
          font-weight: 500;
          font-size: 14px;
        }
        p {
          color: rgba(44, 49, 59, 1);
          margin: 0 0 15px;
          font-family: inherit;
          font-weight: 500;
          font-size: 12px;
        }
        textarea.form-control {
          height: 275px;
          background: rgba(255, 255, 255, 1);
          border: 0.5px solid rgba(228, 229, 238, 1);
          border-radius: 8px;
          width: 350px;
          color: rgba(44, 49, 59, 1);
          font-family: inherit;
          font-weight: 500;
          padding: 15px;
          font-size: 14px;
          text-align: left;
          resize: none;
        }
        .form-group {
          margin: 20px 0 0;
          padding-top: 10px;
          .img {
            width: 36px;
            height: 36px;
            background: rgba(0, 82, 204, 1);
            position: relative;
            border-radius: 50%;
            font-size: 12px;
            margin-right: 15px;
          }
          .form-control {
            width: 400px;
            height: 48px;
            background: rgba(196, 196, 196, 1);
            opacity: 1;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
