$background: #0e1318;
$stroke-color: #e4e5ee;
$font: $myfont;
$fontLabel: $myfont;
$fontMonospace: $myfont;

$focus: #017eff;

$placeholder-color: #a9acbd;

$margin-base: 8px;
$padding-base: 8px;
$margin-half: $margin-base / 2;
$padding-half: $padding-base / 2;
$margin-tiny: $margin-base / 4;
$padding-tiny: $padding-base / 4;
$margin-double: $margin-base * 2;
$padding-double: $padding-base * 2;
$margin-triple: $margin-base * 3;
$padding-triple: $padding-base * 3;

$font-size-smaller: 10px;

$transparent: rgba(0, 0, 0, 0);

$prussian-blue: #002d52;
$rock-blue: #96b2c9;
$oxford-blue: #334252;
$danger-color: #ff4444;
$warning-color: #ffbb33;
$success-color: rgb(0, 200, 81);
$info-color: #33b5e5;
$danger-color-dark: #cc0000;
$success-color-dark: #007e33;
$warning-color-dark: #ff8800;
$info-color-dark: #0099cc;
$default-color: #2bbbad;
$primary-color: #4285f4;
$secondary-color: #aa66cc;
$default-color-dark: #00695c;
$primary-color-dark: #0d47a1;
$secondary-color-dark: #9933cc;
$purple-color-dark: #4a148c;
//$sidebar-hover-color:#6a1e6b;
$sidebar-hover-color:#b7b3ff;

$color1: #5c07d1;
$color2: #fb3254;
$color3: #14bb7f;
$color4: #6c1c88;
$color5: #36b7d3;
$color6: #298ce8;
$color7: #362bb0;
$color8: #bb6bd9;
$color9: #dc47c4;
$color10: #f68e6d;
$color11: #63831e;
$color12: #a8b700;
$color13: #5094ba;
$color14: #00695c;
$color15: #4f37e3;
$color16: #0052cc;
$color17: #2d323c;
$color18: #ff4d97;
$color19: #ff9900;
$color20: #6c1c88;
$color21: #6200ea;
$color22: #6200ea;
$mainColor: rgba(88, 89, 255, 1);

$color1-ops: rgba(20, 187, 127, 0.1);
$color2-ops: rgba(255, 153, 0, 0.1);
$color3-ops: rgba(255, 77, 151, 0.1);
$color4-ops: rgba(145, 127, 216, 0.1);
$color5-ops: rgba(54, 183, 211, 0.1);
$color6-ops: rgba(41, 140, 232, 0.1);
$color7-ops: rgba(54, 43, 176, 0.1);
$color8-ops: rgba(187, 107, 217, 0.1);
$color9-ops: rgba(220, 71, 196, 0.1);
$color10-ops: rgba(246, 142, 109, 0.1);
$color11-ops: rgba(99, 131, 30, 0.1);
$color12-ops: rgba(168, 183, 0, 0.1);
$color13-ops: rgba(80, 148, 186, 0.1);
$color14-ops: rgba(0, 105, 92, 0.1);
$color15-ops: rgb(190, 181, 250);

$prussian-blue-ops: rgba(0, 45, 82, 0.1);
$rock-blue-ops: rgba(150, 178, 201, 0.1);
$oxford-blue-ops: rgba(51, 66, 82, 0.1);
$danger-color-ops: rgba(255, 68, 68, 0.1);
$warning-color-ops: rgba(255, 187, 51, 0.1);
$success-color-ops: rgba(0, 200, 81, 0.1);
$info-color-ops: rgba(51, 181, 229, 0.1);
$danger-color-dark-ops: rgba(204, 0, 0, 0.1);
$success-color-dark-ops: rgba(0, 126, 51, 0.1);
$warning-color-dark-ops: rgba(255, 136, 0, 0.1);
$info-color-dark-ops: rgba(0, 153, 204, 0.1);
$default-color-ops: rgba(43, 187, 173, 0.1);
$primary-color-ops: rgba(66, 133, 244, 0.1);
$secondary-color-ops: rgba(170, 102, 204, 0.1);
$default-color-dark-ops: rgba(0, 105, 92, 0.1);
$primary-color-dark-ops: rgba(13, 71, 161, 0.1);
$secondary-color-dark-ops: rgba(153, 51, 204, 0.1);
$purple-color-dark-ops: rgba(74, 20, 140, 0.1);

.bg-color1,
.btn-color1 {
  background-color: $color1 !important;
}
.bg-color2,
.btn-color2 {
  background-color: $color2 !important;
}
.bg-color3,
.btn-color4 {
  background-color: $color3 !important;
}
.bg-color4,
.btn-color4 {
  background-color: $color4 !important;
}
.bg-color5,
.btn-color5 {
  background-color: $color5 !important;
}
.bg-color6,
.btn-color6 {
  background-color: $color6 !important;
}
.bg-color7,
.btn-color7 {
  background-color: $color7 !important;
}
.bg-color8,
.btn-color8 {
  background-color: $color8 !important;
}
.bg-color9,
.btn-color9 {
  background-color: $color9 !important;
}
.bg-color10,
.btn-color10 {
  background-color: $color10 !important;
}
.bg-color11,
.btn-color11 {
  background-color: $color11 !important;
}
.bg-color12,
.btn-color12 {
  background-color: $color12 !important;
}
.bg-color13,
.btn-color13 {
  background-color: $color13 !important;
}
.bg-color14,
.btn-color14 {
  background-color: $color14 !important;
}
.bg-color15,
.btn-color15 {
  background-color: $color15 !important;
}

.fg-color1 {
  color: $color1 !important;
}

.fg-color2 {
  color: $color2 !important;
}

.fg-color3 {
  color: $color3 !important;
}

.fg-color4 {
  color: $color4 !important;
}

.fg-color5 {
  color: $color5 !important;
}
.fg-color6 {
  color: $color6 !important;
}
.fg-color7 {
  color: $color7 !important;
}
.fg-color8 {
  color: $color8 !important;
}
.fg-color9 {
  color: $color9 !important;
}
.fg-color10 {
  color: $color10 !important;
}
.fg-color11 {
  color: $color11 !important;
}
.fg-color12 {
  color: $color12 !important;
}
.fg-color13 {
  color: $color13 !important;
}
.fg-color14 {
  color: $color14 !important;
}

.fg-color15 {
  color: $color15 !important;
}
.fg-color17 {
  color: $color17 !important;
}

.fg-gray {
  color: #bbb !important;
}

.bg-oxford-color,
.btn-oxford-color {
  background-color: $oxford-blue !important;
}
.bg-rock-color,
.btn-rock-color {
  background-color: $rock-blue !important;
}
.bg-danger-color,
.btn-danger-color {
  background-color: $danger-color !important;
}
.btn-dark.focus, .btn-dark:focus {
  color: inherit;
}
.bg-warning-color,
.btn-warning-color {
  background-color: $warning-color !important;
}
.bg-success-color,
.btn-success-color {
  background-color: $success-color !important;
}
.bg-success-color-dark,
.btn-success-color-dark {
  background-color: $success-color-dark !important;
}
.bg-info-color,
.btn-info-color {
  background-color: $info-color !important;
}
.bg-info-color-dark,
.btn-info-color-dark {
  background-color: $info-color-dark !important;
}

.bg-danger-color-dark,
.btn-danger-color-dark {
  background-color: $danger-color-dark !important;
}
.bg-warning-color-dark,
.btn-warning-color-dark {
  background-color: $warning-color-dark !important;
}
.bg-default-color,
.btn-default-color {
  background-color: $default-color !important;
}
.bg-primary-color,
.btn-primary-color {
  background-color: $primary-color !important;
}
.bg-secondary-color,
.btn-secondary-color {
  background-color: $secondary-color !important;
}
.bg-default-color-dark,
.btn-default-color-dark {
  background-color: $default-color-dark !important;
}
.bg-primary-color-dark,
.btn-primary-color-dark {
  background-color: $primary-color-dark !important;
}
.bg-secondary-color-dark,
.btn-secondary-color-dark {
  background-color: $secondary-color-dark !important;
}

.bdr-oxford-color {
  border-color: $oxford-blue !important;
}
.bdr-rock-color {
  border-color: $rock-blue !important;
}
.bdr-danger-color {
  border-color: $danger-color !important;
}
.bdr-warning-color {
  border-color: $warning-color !important;
}
.bdr-success-color {
  border-color: $success-color !important;
}
.bdr-success-color-dark {
  border-color: $success-color-dark !important;
}
.bdr-info-color {
  border-color: $info-color !important;
}
.bdr-info-color-dark {
  border-color: $info-color-dark !important;
}
.bdr-danger-color-dark {
  border-color: $danger-color-dark !important;
}
.bdr-warning-color-dark {
  border-color: $warning-color-dark !important;
}
.bdr-default-color {
  border-color: $default-color !important;
}
.bdr-primary-color {
  border-color: $primary-color !important;
}
.bdr-secondary-color {
  border-color: $secondary-color !important;
}
.bdr-default-color-dark {
  border-color: $default-color-dark !important;
}
.bdr-primary-color-dark {
  border-color: $primary-color-dark !important;
}
.bdr-secondary-color-dark {
  border-color: $secondary-color-dark !important;
}

.bdr-color1 {
  border-color: $color1 !important;
}

.bdr-color2 {
  border-color: $color2 !important;
}

.bdr-color3 {
  border-color: $color3 !important;
}

.bdr-color4 {
  border-color: $color4 !important;
}

.bdr-color5 {
  border-color: $color5 !important;
}

.bdr-color6 {
  border-color: $color6 !important;
}
.bdr-color7 {
  border-color: $color7 !important;
}
.bdr-color8 {
  border-color: $color8 !important;
}

.bdr-color9 {
  border-color: $color9 !important;
}

.bdr-color10 {
  border-color: $color10 !important;
}

.bdr-color11 {
  border-color: $color11 !important;
}

.bdr-color12 {
  border-color: $color12 !important;
}

.bdr-color13 {
  border-color: $color13 !important;
}

.bdr-color14 {
  border-color: $color14 !important;
}
.bdr-color15 {
  border-color: $color15 !important;
}
.fg-danger-color {
  color: $danger-color !important;
}
.fg-warning-color {
  color: $warning-color !important;
}
.fg-success-color {
  color: $success-color !important;
}
.fg-info-color {
  color: $info-color !important;
}
.fg-danger-color-dark {
  color: $danger-color-dark !important;
}
.fg-warning-color-dark {
  color: $warning-color-dark !important;
}
.fg-info-color-dark {
  color: $info-color-dark !important;
}
.fg-default-color {
  color: $default-color !important;
}
.fg-primary-color {
  color: $primary-color !important;
}
.fg-secondary-color {
  color: $secondary-color !important;
}
.fg-default-color-dark {
  color: $default-color-dark !important;
}
.fg-primary-color-dark {
  color: $primary-color-dark !important;
}
.fg-secondary-color-dark {
  color: $secondary-color-dark !important;
}
.fg-disabled-color {
  color: gray !important;
}
.fg-success-color-dark {
  color: $success-color-dark !important;
}

.fl-danger-color {
  fill: $danger-color !important;
}
.fl-warning-color {
  fill: $warning-color !important;
}
.fl-success-color {
  fill: $success-color !important;
}
.fl-info-color {
  fill: $info-color !important;
}
.fl-danger-color-dark {
  fill: $danger-color-dark !important;
}
.fl-warning-color-dark {
  fill: $warning-color-dark !important;
}
.fl-info-color-dark {
  fill: $info-color-dark !important;
}
.fl-default-color {
  fill: $default-color !important;
}
.fl-primary-color {
  fill: $primary-color !important;
}
.fl-secondary-color {
  fill: $secondary-color !important;
}
.fl-default-color-dark {
  fill: $default-color-dark !important;
}
.fl-primary-color-dark {
  fill: $primary-color-dark !important;
}
.fl-secondary-color-dark {
  fill: $secondary-color-dark !important;
}
.fl-success-color-dark {
  fill: $success-color-dark !important;
}

.bg-ops-color1 {
  background-color: $color1-ops !important;
}
.bg-ops-color2 {
  background-color: $color2-ops !important;
}

.bg-ops-color3 {
  background-color: $color3-ops !important;
}

.bg-ops-color4 {
  background-color: $color4-ops !important;
}

.bg-ops-color5 {
  background-color: $color5-ops !important;
}

.bg-ops-color6 {
  background-color: $color6-ops !important;
}

.bg-ops-color7 {
  background-color: $color7-ops !important;
}

.bg-ops-color8 {
  background-color: $color8-ops !important;
}

.bg-ops-color9 {
  background-color: $color9-ops !important;
}

.bg-ops-color10 {
  background-color: $color10-ops !important;
}

.bg-ops-color11 {
  background-color: $color11-ops !important;
}

.bg-ops-color12 {
  background-color: $color12-ops !important;
}

.bg-ops-color12 {
  background-color: $color13-ops !important;
}

.bg-ops-color14 {
  background-color: $color14-ops !important;
}
.bg-ops-color15 {
  background-color: $color15-ops !important;
}

.bg-ops-oxford-color {
  background-color: $oxford-blue-ops !important;
}
.bg-ops-rock-color {
  background-color: $rock-blue-ops !important;
}
.bg-ops-danger-color {
  background-color: $danger-color-ops !important;
}
.bg-ops-warning-color {
  background-color: $warning-color-ops !important;
}
.bg-ops-success-color {
  background-color: $success-color-ops !important;
}
.bg-ops-success-color-dark {
  background-color: $success-color-dark-ops !important;
}
.bg-ops-info-color {
  background-color: $info-color-ops !important;
}
.bg-ops-info-color-dark {
  background-color: $info-color-dark-ops !important;
}
.bg-ops-danger-color-dark {
  background-color: $danger-color-dark-ops !important;
}
.bg-ops-warning-color-dark {
  background-color: $warning-color-dark-ops !important;
}
.bg-ops-default-color {
  background-color: $default-color-ops !important;
}
.bg-ops-primary-color {
  background-color: $primary-color-ops !important;
}
.bg-ops-secondary-color {
  background-color: $secondary-color-ops !important;
}
.bg-ops-default-color-dark {
  background-color: $default-color-dark-ops !important;
}
.bg-ops-primary-color-dark {
  background-color: $primary-color-dark-ops !important;
}
.bg-ops-secondary-color-dark {
  background-color: $secondary-color-dark-ops !important;
}

.fg-70738b {
  color: #70738b;
}

.fg-3c4043{
  color: #3c4043
}

.fg-202124{
  color: #202124
}


.fg-6f7888 {
  color: #6f7888;
}

.fg-ff9900 {
  color: #ff9900;
}

.fg-14bb7f {
  color: #14bb7f;
}

.fg-ff3a3a {
  color: #ff3a3a;
}

.color16{
  color: $color16;
}

.color16-fw600{
  color: $color16;
  font-weight: 600;
}
.color17 {
  color: #090b0e;
}

